import Unveiling_IPO from "./Images/Unveiling_IPO.jpg";
import MoneyMasterclass from "./Images/MoneyMasterclasscopy.png";
import BiddingBlitz from "./Images/BiddingBlitz.png";
import CollegeTrading from "./Images/CollegeTrading.png";
import Finlatics from "./Images/Finlatics.png";
import KharchePeCharche from "./Images/KharchePeCharche.png";
import Melange from "./Images/Melange.png";
import Orientation from "./Images/Orientation.png";
import Speaker from "./Images/Speaker.png";

const events = [
  {
    name: 'Unveiling IPOs',
    photo: Unveiling_IPO,
    link: 'https://www.canva.com/design/DAGbu4uhDfU/AVRFjLBax5A2e-bE1gvttw/view?utm_content=DAGbu4uhDfU&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hb1e6d44f9b',
    byline: 'Discover the secrets of IPOs',
    year: 2025,
    backgroundColor: '#439543'
  },
  {
    name: 'Money Masterclass',
    photo: MoneyMasterclass,
    link: '/resources/moneymasterclass',
    byline: 'Learn payroll systems',
    year: 2024,
    backgroundColor: '#079b6e'
  },
  {
    name: 'Finlatics',
    photo: Finlatics,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7160561756463853568/',
    byline: 'Explore financial literacy with Finlatics',
    year: 2024,
    backgroundColor: '#fea106'
  },
  {
    name: 'Money Melange',
    photo: Melange,
    link: 'https://www.instagram.com/p/C2CkzBTStUb/?igsh=MW5lNmZ6NmRxZTdqMA==',
    byline: 'A blend of money and fun',
    year: 2024,
    backgroundColor: '#c5f7c5'
  },
  {
    name: 'Kharche Pe Charcha',
    photo: KharchePeCharche,
    link: 'https://www.instagram.com/p/C2AVhH4SE0P/',
    byline: 'Discussing expenses',
    year: 2024,
    backgroundColor: '#156c53'
  },
  {
    name: 'Bidding Blitz',
    photo: BiddingBlitz,
    link: 'https://www.instagram.com/p/CzqYKwRLp24/',
    byline: 'Exciting bidding competition',
    year: 2023,
    backgroundColor: '#70b236'
  },
  {
    name: 'Guide To Stock Market',
    photo: Speaker,
    link: 'https://www.instagram.com/p/CzbC7cLPrex/',
    byline: 'Learn about the stock market',
    year: 2023,
    backgroundColor: '#1f433d'
  },
  {
    name: 'College Trader Showdown',
    photo: CollegeTrading,
    link: 'https://www.instagram.com/p/CxXRs1Pywdn/',
    byline: 'College trading competition',
    year: 2023,
    backgroundColor: '#3aed36'
  },
  {
    name: 'Orientation',
    photo: Orientation,
    link: 'https://www.instagram.com/p/CxAA9weLEea/?img_index=1',
    byline: 'Welcome to the new members',
    year: 2023,
    backgroundColor: '#6eb335'
  },
];

export default events;